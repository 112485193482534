.disposition-updated {
  margin-right: 6px;
  margin-bottom: 1px;
  width: 12px;
  height: 12px;
}

.Campaign-info-label {
  width: 99px;
  height: 10px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}

.campaign-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Campaign-details-key {
  font-family: Inter;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #9b9b9b;
}

.Campaign-details-value {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}

.campaign-info {
  margin: 24px;
}

.Exit-campaign {
  width: 80px;
  height: 15px;
  position: absolute;
  bottom: 40px;
  margin: 0px 110px 0px 110px;
  font-family: Inter;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #5c5c5c;
  text-decoration: underline;
  cursor: pointer;
}

.campaign-completion-text {
  width: 168px;
  height: 24px;
  margin: 0px 66px 0px 66px;
  font-family: Inter;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #9b9b9b;
  position: absolute;
  bottom: 10px;
}

.Customer-outreach-label {
  width: 168px;
  height: 24px;
  margin: 0px 48px 24px;
  font-family: Inter;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #9b9b9b;
  display: inline-block;
}

.Campaign-exited-label {
  width: 113px;
  height: 17px;
  margin: 24px 76px 16px 75px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000;
  display: inline-block;
}

.Choose-another-campaign-label {
  width: 150px;
  height: 15px;
  margin: 16px 57px 0;
  font-family: Inter;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #196cca;
  display: inline-block;
  text-decoration: underline;
  cursor: pointer;
  position: absolute;
  bottom: 18px;
}

/* Call Merging */

.call-merge-add-a-call{
  color: var(--Black, #000);
text-align: center;
font-family: Inter;
font-size: var(--Title-Small-Size, 14px);
font-style: normal;
font-weight: 600;
line-height: var(--Title-Small-Line-Height, 20px); /* 142.857% */
letter-spacing: var(--Title-Small-Tracking, 0.1px)
}

.call-merge-back-button{
  color: var(--Alohaa-Blue, #196CCA);
  margin-right: 2px;
leading-trim: both;
text-edge: cap;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
cursor: pointer;
}

.call-merge-customer-name{
  color: var(--Black, #000);
leading-trim: both;
text-edge: cap;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.call-merge-customer-number{
  color: #5C5C5C;
leading-trim: both;
text-edge: cap;
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.call-merge-customer-call-duration{
  color: var(--Gray, #9B9B9B);
  text-align: right;
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.call-merge-customer-call-status{
  color: var(--Alohaa-Green, #07AA3F);
text-align: right;
leading-trim: both;
text-edge: cap;
font-family: Inter;
font-size: 12px;
font-style: italic;
font-weight: 400;
line-height: normal;
}

.call-merge-agent-search{
  color: var(--Gray, #9B9B9B);
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 90%;
  border: none;
  background: #FEF7FF;
  outline: none;
}

.call-merge-agent-name{
  color: var(--Black, #000);
leading-trim: both;
text-edge: cap;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.call-merge-status {
  leading-trim: both;
  text-edge: cap;
  font-feature-settings: 'cv01' on;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  text-transform: capitalize;
  line-height: 20px; 
  display: flex;
  align-items: center;
  gap: 3px;
}

.call-merge-status-online {
  color: var(--Alohaa-Green, #07AA3F);
}

.call-merge-status-offline {
  color: var(--Gray, #9B9B9B);
  font-family: Inter;
}

.call-merge-status-busy {
  color: red;
  font-family: Inter;
}

.call-merge-status-break {
  color: #E7BE54;
}


.call-merge-colored-dot {
  width: 9px;
  height: 9px;
  border: 2px solid;
  border-radius: 100%;
}

.call-merge-status-colored-dot-online {
  background-color: #07AA3F;
}

.call-merge-status-colored-dot-break {
  background-color: #E7BE54;
}

.call-merge-status-colored-dot-busy {
  background-color: red;
}

.call-merge-status-colored-dot-offline {
  background-color: #9B9B9B;
}

.call-merge-add-to-call-button{
  color: #FFF;
leading-trim: both;
text-edge: cap;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: normal;
}

.call-merge-disposition-text{
  color: var(--Gray, #9B9B9B);
leading-trim: both;
text-edge: cap;
font-family: Inter;
font-size: 12px;
font-style: italic;
font-weight: 400;
line-height: normal;
}

.dailer-modal-box {
  position: absolute; 
  z-index: 10;      
  width: 100%;     
  padding: 40px;
}

.dailer-modal-box .modal-dialog .modal-content {
  border-radius: 12px;
}

.modal-merge-call-end-header{
  color: var(--Black, #000);
  margin-top:-5px;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.modal-merge-call-end-description{
  color: #5C5C5C;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.modal-merge-call-end-me{
  width:160px;
  border-radius: var(--32, 32px);
  border: 1px solid var(--Alohaa-Red, #F8153B);
  background: #FFF;
  padding: 10px 20px 10px 20px;
  color: var(--Alohaa-Red, #F8153B);
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
}

.modal-merge-call-end-everyone{
  width:160px;
  border-radius: var(--32, 32px);
  background: var(--Alohaa-Red, #F8153B);
  color: #FFF;
  padding: 10px 20px 10px 20px;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
}

.modal-merge-call-end-back{
  color: var(--Alohaa-Red, #F8153B);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
  cursor: pointer;
}

.call-transfer-group-agent-type{
  color: #5C5C5C;
  margin-left: 14px;
leading-trim: both;
text-edge: cap;
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.modal-transfer-call-end-header{
  width: 140px;
  color: var(--Black, #000);
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.modal-transfer-call-end-description{
  width: 152px;
  color: var(--Gray, #9B9B9B);
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.modal-transfer-forward-call{
  width: 152px;
  border-radius: var(--32, 32px);
  border: 1px solid var(--Alohaa-Green, #07AA3F);
  background: var(--Alohaa-Green, #07AA3F);
  padding: 10px 20px 10px 20px;
  color: #FFF;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
}

.customer-info {
  color: var(--Black, #000);
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 24px;
}

.customer-info-header {
  color: var(--Gray, #9B9B9B);
leading-trim: both;
text-edge: cap;
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-align: left;
margin-left: 24px;
margin-bottom: 7px;
}

.customer-info-value {
  color: var(--Black, #000);
leading-trim: both;
text-edge: cap;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: normal;
text-align: left;
margin-left: 24px;
margin-bottom: 11px;
}

.customer-info-call-box {
  border-radius: 0px 0px 12px 12px;
background: var(--White, #FFF);
box-shadow: 0px -4px 4px 0px rgba(0, 0, 0, 0.12);
height: 59px;
}

.customer-info-name {
  color: var(--Black, #000);
leading-trim: both;
text-edge: cap;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
position: absolute;
left: 24px;
bottom: 32px;
text-align: left;
}

.customer-info-number {
  color: #5C5C5C;
leading-trim: both;
text-edge: cap;
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
position: absolute;
left: 24px;
bottom: 16px;
text-align: left;
}

.customer-info-timer {
  color: #000;
text-align: right;
leading-trim: both;
text-edge: cap;
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
position: absolute;
right: 70px;
bottom: 32px;
text-align: right;
}

.customer-info-status {
text-align: right;
leading-trim: both;
text-edge: cap;
font-family: Inter;
font-size: 12px;
font-style: italic;
font-weight: 400;
line-height: normal;
position: absolute;
right: 70px;
bottom: 16px;
text-align: right;
font-weight: 700;
}

.customer-info-hangup {
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 22px;
  background:  #F8153B;
  position: absolute;
  bottom: 11px;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.only-incoming-calls{
  width: 100%;
  height:100%;
  /* border: 3px dotted; */
  padding:10px;
  margin: 80px 130px 10px 0px;
  font-family: Inter;
  font-size: 28px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #9b9b9b;
  display: inline-block;
}

.incoming-ring-toggle{
  position: absolute;
  right: 50px;
  top: 10px;
}

.exit-predictive-campaign {
  width: 150px;
  height: 15px;
  position: absolute;
  bottom: 18px;
  left: 80px;
  font-family: Inter;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #196cca;
  display: inline-block;
  text-decoration: underline;
  cursor: pointer;
}

/* Network Status - start */

.offlineNetworkStatus{
  background: red ;
  height: 18px;
  width: 100%;
  margin-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: white;
  padding: 11px;
  font-family: Inter;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.LowNetworkStatus{
  background: red ;
  height: 18px;
  width: 100%;
  margin-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: white;
  padding: 11px;
  font-family: Inter;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

/* Network Status - end */